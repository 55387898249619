/*

Example Steps  
[
  {
      title: "Basic Info",
      // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
      bottomText: "Next you will input some more details and complete the application.",
      inputFields: [
          
      // Regular text input
          {
              key: "firstName",
              label: "Legal First Name",
              type: "text",
              optional: false, // Is it not optional (so it is required) by default
              // defaultValue: applicantFirstName, // This won't save into the db unless all of the inputs are saved when one changes, currently just the one changing saves
          },

          // Selection input
          {
              key: "gender",
              label: "Gender",
              type: "select",

              fullWidth: false,
              options: [
                  "",
                  "Male",
                  "Female",
                  "Other",
                  "Prefer Not To Say",
              ]
          },

          // File upload input
          {
              key: "driversLicenceBack",
              label: "Upload a picture of the back side of your ID.",
              type: "file",
              fullWidth: true,
          },

          ... other input fields
      ]
  },

  ... other steps
]

*/
/*
  Job types
  Delivery driver, house cleaner, register worker
  
  manufacturing engineer, 

  https://www.gumtree.com/jobs/uk/longford
  https://recruiters.gumtree.com/

  hr@integrity-staffing.co

*/

// Default for the apply box (ApplyBox2.js)
export const defaultStepsData = [
  {
      title: "Basic Info",
      // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
      bottomText: "Next you will input some more details and complete the application.",
      inputFields: [
          {
              key: "firstName",
              label: "Legal First Name",
              type: "text",
          },
          {
              key: "lastName",
              label: "Legal Last Name",
              type: "text",
          },
          {
              key: "phone",
              label: "Phone Number",
              type: "text",
          },
          {
              key: "email",
              label: "Email Address",
              type: "text",
          },

      ]
  },
  {
      title: "Detailed Info",
      bottomText: "Next: You can review your inputs before submission.",
      inputFields: [
          {
              key: "age",
              label: "Age",
              type: "text",
              fullWidth: false,
          },
          {
              key: "gender",
              label: "Gender",
              type: "select",
              fullWidth: false,
              options: [
                  "",
                  "Male",
                  "Female",
                  "Other",
                  "Prefer Not To Say",
              ]
          },
          {
              key: "eligableToWork",
              label: "Eligable To Work In This Region?",
              type: "select",
              fullWidth: false,
              options: [
                  "",
                  "Yes",
                  "No"
              ]
          },
          {
              key: "referred",
              label: "Referred By Existing Employee?",
              type: "select",
              optional: true,
              fullWidth: false,
              options: [
                  "",
                  "No",
                  "Yes",
              ]
          },
          // {
          //     key: "mailingAddress",
          //     label: "Mailing Address",
          //     type: "text",
          //     fullWidth: true,
          // },
          {
              key: "smLinks",
              label: "Social Media Links (Facebook, Instagram, etc.)",
              type: "textarea",
              fullWidth: true,
              optional: true
          },
          {
              key: "resumeFile",
              label: "Upload Resume",
              type: "file",
              fullWidth: true,
              optional: true
          },
      ]
  },
]

export const applyInputStepsStudentDriver = [
  // Basic info
  {
      title: "Basic Info",
      // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
      bottomText: "Next: you will input some more details and complete the application.",
      inputFields: [
          {
              key: "firstName",
              label: "Legal First Name",
              type: "text",
          },
          {
              key: "lastName",
              label: "Legal Last Name",
              type: "text",
          },
          {
              key: "phone",
              label: "Phone Number",
              type: "text",
          },
          {
              key: "email",
              label: "Email Address",
              type: "text",
          },
          {
              key: "address",
              label: "Home Address",
              type: "text",
              fullWidth: true,
          },
          {
            key: "resumeFile",
            label: "Upload Resume (optional)",
            type: "file",
            fullWidth: true,
            optional: true
        },
      ]
  },
  // Student Info
  {
    title: "Student Info",
    bottomText: "Thanks for you input! Next: Some driver information (role specific)",
    inputFields: [
        {
            key: "studentStatus",
            label: "Are you currently a full time student? (important information for tax purposes)",
            type: "select",
            fullWidth: true,
            options: [
              "",
              "Yes",
              "No",
            ]
        },
        {
            optional: true,
            key: "universityName",
            label: "Name of the University you attend",
            type: "text",
            optional: true,
        },
        {
            optional: true,
            key: "studentID",
            label: "Student ID Number",
            type: "text",
            optional: true,
        },
        {
            key: "studentIdPic",
            label: "A picture of your student ID or Certificate (if any)",
            type: "file",
            fullWidth: true,
            optional: true,
        },
    ]
  },
  // Driver Info
  {
    title: "Driver Info",
    bottomText: "Your all set! Thanks for you input! Next: Review and submit",
    inputFields: [
      {
          key: "driversLicenceStatus",
          label: "Do you currently have a drivers licence?",
          fullWidth: true,
          type: "select",
          options: [
            "",
            "Yes",
            "No",
          ]
      },
      {
        key: "driversLicenceFront",
        label: "Provide a picture of drivers licence ",
        type: "file",
        optional: true,
      },
      {
          key: "driversLicenceBack",
          label: "Provide a picture of the back side of your drivers licence",
          type: "file",
          optional: true,
      },
      {
          key: "carStatus",
          label: "Do you currently have a car?",
          fullWidth: true,
          type: "select",
          options: [
            "",
            "Yes",
            "No",
          ]
      },
      {
          key: "carMakeModel",
          label: "The Make and Model of your car (ex Kia Nitro)",
          type: "text",
          optional: true,
      },
      {
          key: "carReg",
          label: "Your Car Reg #",
          type: "text",
          optional: true,
      },
    ]
  },
]

export const companyData = {
  "Freedom-Fertility": {
    name: "Freedom Fertility",
    description: `
Funded by the Sarah Johnson Foundation for Human Development all of our services are provided completely free of charge. These services are available to everyone regardless of economic status or orientation.

One of our founding beliefs is that everyone should be able to experience the joy and purpose of parenthood regardless of their orientation. We aim to make this more accessable to everyone. Due to the nature of our service many of our users are members of the LGBT community so if you are member of this community you can rest assured you are very welcome.

The services provide on our platform enable intended parents to start their journey.

    `,
    reviews: [
      {
        reviewer: "Anonymouse User",
        rating: 5,
        comment: "Really nice people working here and I like their mission"
      },
      {
        reviewer: "Anonymouse User",
        rating: 4,
        comment: "Liked it there."
      },
      {
        reviewer: "Anonymouse User",
        rating: 5,
      },
    ],
    openPositions: {
      // React-Developer
      "React-Developer": {
        title: "React Developer",
        description: "Seeking front end / full stack react developers with 2-5 years of experience",
        // The company name and companyID should be obtained when parsing this into tiles that are displayed so the name displays properly and the user can click them to go to the compnay page
        
        hilights: `
Qualifications:

• 2-5 years of experience with React or similar front-end frameworks
• Strong proficiency in CSS and experience with no-sql databases
• Ability to build responsive UI components and robust user interfaces
• Passion for clean code and attention to detail

Benefits:

• Competitive salary range (85k - 125k) with performance bonuses
• Opportunities for remote work and flexible scheduling
• Comprehensive health and wellness benefits
• Collaborative and supportive team environment
      `,
          descriptionLong: `
      
As a React Developer you will be at the forefront of designing cutting-edge web applications. Your role will involve creating dynamic components, resolving bugs, enhancing existing functionalities, and integrating APIs. 

Strong CSS skills and experience with no-sql databases will enable you to develop robust and scalable solutions. If you have a passion for front-end development and the React framework, we encourage you to apply and join our mission-driven team.
      
  `,
      payRate: "85k - 125k",
      tags: ["85k - 125k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
    },
    // Database-Design
    "Database-Design": {
      title: "Database Design / Admin",
      description: "Seeking no-sql database administrators with 2-5 years of experience",
      
      hilights: `
Qualifications:

• 2-5 years of experience in database design and administration
• Proficient with no-sql databases and data modeling
• Familiarity with React-Redux framework for front-end integration
• Strong problem-solving skills and attention to data security

Benefits:

• Competitive salary range (85k - 125k) with performance incentives
• Remote work flexibility and supportive work culture
• Professional development opportunities and career growth
• Health insurance and retirement plan options
      `,
      descriptionLong: `
      
As a Database Administrator at FreeFer, your role will involve innovatively designing no-sql database structures and ensuring seamless integration with front-end frameworks. Your expertise in database design, administration, and security will be pivotal in supporting our dynamic web applications. 

Experience with the React-Redux framework will be advantageous in this role. If you are driven by challenges in data management and eager to contribute to impactful projects, we welcome your application.
      
  `,
      "payRate": "85k - 125k",
      "tags": ["85k - 125k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
    },
    // Scrum Master
    "Scrum-Master": {
      title: "Scrum Master",
      description: "Seeking an experienced scrum master or software development manager",
      
      hilights: `
Qualifications:

• Proven experience as a Scrum Master or in a similar role in software development
• Excellent skills in facilitating, scheduling, and conducting scrum ceremonies
• Ability to support development teams in resolving logistical and technical blockers
• Strong communication and leadership skills

Benefits:

• Competitive salary (75k - 100k) with potential bonuses
• Flexible and remote working options
• A dynamic and supportive team environment
• Comprehensive benefits package including health coverage
      `,
        descriptionLong: `
      
In the Scrum Master role at FreeFer, you will be a key facilitator in driving our software development projects towards success. Your primary responsibilities include scheduling and leading scrum ceremonies, such as daily stand-ups and backlog refinement, while providing continuous support to our development team. 

Your experience and leadership will be instrumental in maintaining a productive and positive work environment. If you are passionate about agile methodologies and team collaboration, we would be excited to have you on board.
      
      `,
          "payRate": "75k - 100k",
          "tags": ["75k - 100k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
        },
        // HR-Specialist
        "HR-Specialist": {
          title: "HR Specialist",
          description: "Seeking experienced HR specialists",
          
          hilights: `
Qualifications:

• Experience in human resources management, payroll, and personnel administration
• Knowledge of HR laws and regulations
• Strong organizational skills and proficiency in HR software
• Excellent communication and interpersonal skills

Benefits:

• Competitive salary (65k - 85k) with annual bonuses
• Flexible working hours and remote work options
• Health benefits and professional development programs
• A collaborative and inclusive workplace culture
      `,
          descriptionLong: `
      
As an HR Specialist at FreeFer, you will be responsible for managing various HR tasks including payroll, scheduling, personnel management, and staying up-to-date with applications. Your role is integral in ensuring smooth and efficient HR operations within our company. 

We are looking for individuals with solid experience in HR or relevant education to join our team. Your contribution will be key to fostering a healthy and productive work environment.
      
      `,
          "payRate": "65k - 85k",
          "tags": ["65k - 85k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
        },
        // AI-Integration-Specialist
        "AI-Integration-Specialist": {
          title: "AI Integration Specialist",
          description: "Seeking an AI integration specialist",
          
          hilights: `
Qualifications:

• Experience with TensorFlow and AI model creation
• Knowledge in utilizing existing AI models in web development
• General web development skills including front-end and back-end technologies
• Passion for AI technologies and innovation

Benefits:

• Top-tier salary range (125k - 150k) with performance bonuses
• Flexible work arrangements and remote work options
• Access to cutting-edge technology and resources
• Health benefits and a supportive team environment
      `,
          "descriptionLong": `
      
Joining FreeFer as an AI Integration Specialist means you will be engaging with groundbreaking AI technologies. Your role involves creating and implementing AI models into various web frameworks, contributing to innovative solutions. 

Your experience with TensorFlow, model development, and overall web development expertise will be crucial. If AI innovation excites you and you're ready to make significant contributions, we encourage you to apply.
      
      `,
          "payRate": "125k - 150k",
          "tags": ["125k - 150k", "Full Time", "Remote Available", "Benefits", "Experience Required"]
        },
          "Customer-Service-Representative": {
            "title": "Customer Service Representative",
            "description": "Looking for customer-oriented representatives.",
            "hilights": `
    Qualifications:
    
      • Understanding and patient
      • Computer Literacy
      • Ability to communicate effectively 
      • Upbeat attitude  
    
    Benefits:
    
      • Competitive salary (45k - 55k)
      • Remote work available
      • Health Plans
            `,
            "descriptionLong": `
    Your task will be to assist customers with any questions or technical needs they may have. It's mostly just simple questions and scheduling, and you will be provided on-going training.
            `,
            "payRate": "45k - 55k",
            "tags": ["45k - 55k", "Full Time", "Part Time", "Remote Available", "Customer Service"]
          },
    }
  },
  "CallCenterCo": {
    "name": "Real-Time Call Center Solutions",
    "description": "Call Center Co. specializes in providing exceptional customer service and support. Our team is dedicated to effective communication and problem-solving, ensuring customer satisfaction. We are currently expanding our team and looking for motivated individuals to join us in various roles, including call center representatives and office staff.",

    "reviews": [
      {
        "reviewer": "Anonymous User",
        "rating": 5,
      },
      {
        "reviewer": "Anonymous User",
        "rating": 4,
        "comment": "Great team environment and supportive management."
      },
      {
        "reviewer": "Anonymous User",
        "rating": 5,
        "comment": "Was promoted pretty fast and like the new role."
      },
      {
        "reviewer": "Anonymous User",
        "rating": 2,
        "comment": "Management could be improved."
      }
    ],

    "openPositions": {
      "Call-Center-Representative": {
        "title": "Call Center Representative",
        "description": "Looking for customer-oriented call center representatives.",
        "hilights": `
Qualifications:

  • Excellent communication skills and a friendly demeanor
  • Ability to handle customer inquiries and complaints effectively
  • Basic computer proficiency and ability to learn new software
  • High school diploma or equivalent; previous call center experience preferred

Benefits:

  • Competitive salary (30k - 40k) with performance bonuses
  • Flexible shifts and potential for remote work
  • Comprehensive training program and supportive team environment
  • Health benefits and employee wellness programs
        `,
        "descriptionLong": `
As a Call Center Representative, you will be the first point of contact for our customers, handling inquiries and providing solutions. You will receive comprehensive training to ensure you have all the necessary tools and knowledge to excel in this role. This position offers great opportunities for growth and development in customer service.
        `,
        "payRate": "30k - 40k",
        "tags": ["30k - 40k", "Full Time", "Remote Available", "Benefits", "Customer Service"]
      },
      "Office-Manager": {
        "title": "Office Manager",
        "description": "Seeking an experienced and organized Office Manager.",
        "hilights": `
Qualifications:

  • Proven experience in office management or administrative role
  • Strong organizational and planning skills
  • Proficiency in MS Office and office management software
  • Excellent communication and leadership skills

Benefits:

  • Competitive salary (50k - 60k) with bonuses
  • Opportunities for professional growth and development
  • Supportive and dynamic work environment
  • Health insurance and retirement benefits
        `,
        "descriptionLong": `
As an Office Manager, you will oversee daily administrative operations, coordinate with various departments, and ensure a smooth and efficient workplace. Your role is pivotal in maintaining an organized and positive office environment, contributing to the overall success of our team.
        `,
        "payRate": "50k - 60k",
        "tags": ["50k - 60k", "Full Time", "Benefits", "Experience Required", "Leadership"]
      },
      "HR-Coordinator": {
        "title": "HR Coordinator",
        "description": "Seeking a detail-oriented HR Coordinator.",
        "hilights": `
Qualifications:

  • Experience in HR or related field
  • Knowledge of HR policies and procedures
  • Strong interpersonal and communication skills
  • Proficiency in HR software and tools

Benefits:

  • Competitive salary (45k - 55k) with potential for bonuses
  • Career advancement opportunities
  • Positive and inclusive work culture
  • Comprehensive benefits package
        `,
        "descriptionLong": `
As an HR Coordinator, you will assist in various HR functions including recruitment, onboarding, and employee relations. Your role is crucial in supporting our team and ensuring a healthy workplace culture. This position is perfect for those looking to build a career in human resources.
        `,
        "payRate": "45k - 55k",
        "tags": ["45k - 55k", "Full Time", "Benefits", "HR Experience", "Team Support"]
      },
      "Technical-Support-Specialist": {
        "title": "Technical Support Specialist",
        "description": "Seeking knowledgeable Technical Support Specialists for our call center.",
        "hilights": `
      Qualifications:
      
        • Experience in technical support or IT helpdesk roles
        • Strong understanding of computer systems and common software applications
        • Excellent problem-solving and communication skills
        • Ability to provide step-by-step technical help, both written and verbal
      
      Benefits:
      
        • Competitive salary (35k - 45k) with performance incentives
        • Opportunities for career growth and skills development
        • Flexible work hours and remote work options
        • Health insurance and employee assistance programs
        `,
        "descriptionLong": `
      As a Technical Support Specialist, you will be responsible for providing technical assistance and support to customers facing hardware or software issues. This role involves diagnosing and resolving technical issues, guiding customers through troubleshooting steps, and ensuring a high level of customer satisfaction. This position is ideal for those with a passion for technology and customer service.
        `,
        "payRate": "35k - 45k",
        "tags": ["35k - 45k", "Full Time", "Remote Available", "Technical Support", "IT Skills"]
      },        
    }
  },   
  "Bangor-Grill": {
    "name": "Bangor Grill",
    "description": "We are a grill in the heart of Bangor",
    // https://www.google.com/maps/place/Bangor+Grill/@53.2261086,-4.1291588,17z/data=!3m2!4b1!5s0x48650749b0dc24d7:0xa9916d4977dab246!4m6!3m5!1s0x486507b82972c3f7:0x6c2676698bf6d317!8m2!3d53.2261054!4d-4.1265785!16s%2Fg%2F11s861rmpd?entry=ttu
    // 212 High St, Bangor LL57 1NY, United Kingdom
    "reviews": [],
    "openPositions": {
      // https://integrity-staffing.co/#/Bangor-Grill/Delivery-Driver
      "Grill-Chef": {
        "title": "Grill Chef",
        "description": "Come work in our kitchen. ",
        "hilights": `
        Qualifications:
        
          • Ability to work at a grill
          • Ability to follow recipies
          • Ability to stand on your feet for a whole shift
        
        Benefits:
        
          • Competitive salary (30k - 40k) with performance bonuses
          • Flexible shifts and potential for remote work
          • Comprehensive training program and supportive team environment
          • Health benefits and employee wellness programs
        `,
        "descriptionLong": `
          Cook food to satisfy customers at our establishment. 
        `,
        "payRate": "30k - 40k",
        "tags": ["30k - 40k", "Full Time", "Remote Available", "Benefits", "Customer Service"]
      },
      "Delivery-Driver": {
        "title": "Delivery Driver",
        // This application will have custom steps to see if the willingness is there (else will have shorter input steps)
        "customStepsData": applyInputStepsStudentDriver,
        "description": "Seeking a safe driver to deliver food for our restaurant",
        "hilights": `
      Desired Qualifications (not required but preferred):
      
        • Ability to drive (have a drivers licence)
        • Have your own reliable vehicle 
      
      Benefits:
      
        • Competitive salary (20k - 40k) with bonuses
        • Part time and full time available
        • Supportive and dynamic work environment
        `,
        "descriptionLong": `
          We are looking for a delivery driver to help us meet our customer's needs. Students are welcome to apply. Its a plus if you have your own vehicle and already have a drivers licence, but anyone can apply.
        `,
        "payRate": "£20k - £40k",
        "tags": ["20k - 40k", "Full Time", "Part Time", "No Experience Required", "Students Welcome"]
      },      
    }
  },   
}

export const backgroundCheckInputSteps = [
  // Basic Info
  {
      title: "Basic Info",
      // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
      bottomText: "Next you will input a few student details.",
      inputFields: [
          {
              key: "firstName",
              label: "Legal First Name",
              type: "text",
              // defaultValue: applicantFirstName, // This won't save into the db unless all of the inputs are saved when one changes, currently just the one changing saves
          },
          // {
          //     key: "middleName",
          //     label: "Legal Middle Name",
          //     type: "text",
          // },
          {
              key: "lastName",
              label: "Legal Last Name",
              type: "text",
          },
          {
              key: "age",
              label: "Age",
              type: "text",
              fullWidth: false,
          },
          {
              key: "gender",
              label: "Gender",
              type: "select",
              fullWidth: false,
              options: [
                  "",
                  "Male",
                  "Female",
                  "Other",
                  "Prefer Not To Say",
              ]
          },
          {
              key: "mailingAddress",
              label: "Mailing Address",
              type: "text",
              fullWidth: true,
          },
          // May already have these
          {
              key: "phone",
              label: "Phone Number",
              type: "text",
          },
          {
              key: "email",
              label: "Email Address",
              type: "text",
          },
      ]
  },
  // Student Info
  {
      title: "Student Info",
      bottomText: "Thanks for you input! Next: You will input a few driver details.",
      inputFields: [
          {
              key: "studentStatus",
              label: "Are you currently a full time student? (may be needed for tax purposes)",
              type: "select",
              fullWidth: true,
              options: [
                "",
                "Yes",
                "No",
              ]
          },
          {
              optional: true,
              key: "universityName",
              label: "Name of the University you attend",
              type: "text",
              optional: true,
          },
          {
              optional: true,
              key: "studentID",
              label: "Student ID Number",
              type: "text",
              optional: true,
          },
          {
              key: "studentIdPic",
              label: "A picture of your student ID",
              type: "file",
              fullWidth: true,
              optional: true,
          },
      ]
  },
  // Driver Info
  {
      title: "Driver Info",
      bottomText: "Thanks for you input! Next: Last page before submission.",
      inputFields: [
        {
            key: "driversLicenceStatus",
            label: "Do you currently have a drivers licence?",
            fullWidth: true,
            type: "select",
            options: [
              "",
              "Yes",
              "No",
            ]
        },
        {
          key: "driversLicenceFront",
          label: "Provide a picture of drivers licence ",
          type: "file",
          optional: true,
        },
        {
            key: "driversLicenceBack",
            label: "Provide a picture of the back side of your drivers licence",
            type: "file",
            optional: true,
        },
        {
            key: "carStatus",
            label: "Do you currently have a car?",
            fullWidth: true,
            type: "select",
            options: [
              "",
              "Yes",
              "No",
            ]
        },
        {
            key: "carMakeModel",
            label: "The Make and Model of your car (ex Kia Nitro)",
            type: "text",
            optional: true,
        },
        {
            key: "carReg",
            label: "Your Car Reg #",
            type: "text",
            optional: true,
        },
      ]
  },
  // Passport
  {
      title: "Passport",
      bottomText: "Next: You will review your inputs before submission.",
      inputFields: [
        {
          key: "nationalInsuranceNumber",
          label: "National Insurance Number (NIN)",
          type: "text",
        },
        {
          key: "passportStatus",
          label: "Do you currently have a passport?",
          type: "select",
          fullWidth: true,
            options: [
              "",
              "Yes",
              "No"
            ]
        },
        {
            key: "passportFront",
            label: "Provide a picture of your passport (front side)",
            type: "file",
            optional: true,
        },
        {
            key: "passportBack",
            label: "Provide a picture of your passport (back side)",
            type: "file",
            optional: true,
        },
      ]
  },

]
