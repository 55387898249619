import React, { useEffect, useRef, useState } from 'react'
import "./ApplyPage.css"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from '@firebase/storage'
import { db, storage } from '../../GlobalState/AppSlice'
import {  push, ref, set, update } from '@firebase/database'
import { dateStringUTC } from '../../Global/Functions'
import FileUploader from '../FileUploader/FileUploader'

/*
    ================================================================================
    
    This component displays an application form with multipla parts
    It saves the application data to a database
    There is an optional pin resume functionality
    
    ================================================================================
    To Add

    ssn (maybe check it somehow)
    id upload (image)
    # or #
    remove un necessary inputs and combine some sections

    ssn check based on birthdate and birth state
    call it form an API
    https://www.ssn-check.org/verify/013-78-5555


    
    upload responses to a db
    and have admin board with all of the applications


    __________
    can put out add for income share agreement and have people apply
    
    can pay people to apply to a college
    have a booth at a college or high school or random place
    pay them to fill out the application on their phone (so they can easily take a picture of their licence)

    can have job adds and get that info from there
    put basic info first
    then call them to say going forward
    maybe even do an interview
    then they fill out an employment form with the detailed info

    can put careers page here or on other sites and post them on job search htings like 
    craigslist or
    https://www.gumtree.com/p/domestic-jobs/cleaner-full-time-part-time-cleaning-jobs-immediate-start/5413351660

    __________
    make seem like its their idea even if I approach them
    present the problem and brainstorm together

    in uk ssn =  National Insurance number



    ================================================================================
    Page Flow
    ================================================================================

    stepIndex starts at 0
    user inputs into field which saves in tempResponsObject
    when user clicks next addTempResponses is called
        if the inpus are valid:
            responses are saved in responseObject, which saves in localStorage when it changes via a useEffect
            the stepIndex is incremented and the next set of inputs are displayed
        if some inputs are not valid
            a mressage is displayed that tells the user what to look at
    
    when the user leaves and returns:
        responseObject, stepIndex, and submitted are loaded from local storage
        if there is a pin in the responseObject a window shows asking the user to enter a pin to contiue or restart
    
    when the user submitts the application
    this is saved in state and local storage
    a message is displayed that allows them to review or start a new application

    ================================================================================
    Page Flow 2
    ================================================================================

    1)
    An applicationName local variable is set (comes in as a parameter or is generated)

    2)
    Some variables are loaded that will affect what is displayed:
        stepIndex:      Used to resume user step progress
        submitted:      If the application has been submitted

    3) 
    An application ID (The ID of the application, used as a key to save and retrieve data) is retrieved or generated and saved
      The applicationID is saved in localStorage under applicationName+ID

    4)
    The applicationData corresponding to the stepIndex is displayed
    
    5)
    User inputs into these fields 
        The data is saved in the database at applications/applicationID

    6)
    When the user clicks next 
        The next index of input data is displayed
        The index is saved in applicationName+Step so the user can resume progress
        
    7)
    When the final index is reached a review page is displayed
    
    8)
    When the user clicks next applicationName+Submitted is set to true

    9)
    User may click review application or start over
        Review option will set submitted to false so the review page shows
        Starting over will remove the localStorage data, reset state, and start an new applicatoin

*/
function ApplyPage2({applicationData, stepsDataParam, applicationNameParam, requiresPin}) {

    // ================================================================================
    // #region Initial values: stepsData, applicationLocalName, requiresPin
    
    // The data for each step, includes inputs and bottom messages
    const stepsDataDefault = [
        {
            title: "Basic Info",
            // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
            bottomText: "Next you will input some more details and complete the application.",
            inputFields: [
                {
                    key: "firstName",
                    label: "Legal First Name",
                    type: "text",
                },
                {
                    key: "lastName",
                    label: "Legal Last Name",
                    type: "text",
                },
                {
                    key: "phone",
                    label: "Phone Number",
                    type: "text",
                },
                {
                    key: "email",
                    label: "Email Address",
                    type: "text",
                },

            ]
        },
        {
            title: "Detailed Info",
            bottomText: "Next: You can review your inputs before submission.",
            inputFields: [
                {
                    key: "age",
                    label: "Age",
                    type: "text",
                    fullWidth: false,
                },
                {
                    key: "gender",
                    label: "Gender",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "Male",
                        "Female",
                        "Other",
                        "Prefer Not To Say",
                    ]
                },
                {
                    key: "eligableToWork",
                    label: "Eligable To Work In This Region?",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "Yes",
                        "No"
                    ]
                },
                {
                    key: "referred",
                    label: "Referred By Existing Employee?",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "No",
                        "Yes",
                    ]
                },
                // {
                //     key: "mailingAddress",
                //     label: "Mailing Address",
                //     type: "text",
                //     fullWidth: true,
                // },
                {
                    key: "smLinks",
                    label: "Social Media Links (Facebook, Instagram, etc.)",
                    type: "textarea",
                    fullWidth: true,
                },
                {
                    key: "resumeFile",
                    label: "Upload Resume",
                    type: "file",
                    fullWidth: true,
                },
            ]
        },
    ]
    const unusedStepsData = [
        {
            title: "Basic Info",
            // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
            bottomText: "Next you will input some more details and complete the application.",
            inputFields: [
                {
                    key: "firstName",
                    label: "Legal First Name",
                    type: "text",
                },
                {
                    key: "lastName",
                    label: "Legal Last Name",
                    type: "text",
                },
                {
                    key: "phone",
                    label: "Phone Number",
                    type: "text",
                },
                {
                    key: "email",
                    label: "Email Address",
                    type: "text",
                },
                // {
                //     key: "collegeOfInterest",
                //     label: "Select Your College Of Interest",
                //     type: "select",
                //     fullWidth: true,
                //     options:[ 
                //         "",
                //         "Business",
                //         "Information Technology",
                //         "Healthcare",
                //         "Education",
                //     ]
                // },
                // {
                //     key: "resumeFile",
                //     label: "Upload Resume",
                //     type: "file",
                //     fullWidth: true,
                // },
                // {
                //     key: "pin",
                //     label: "PIN",
                //     type: "password",
                // },
                // {
                //     key: "pinRetype",
                //     label: "PIN Re-type",
                //     type: "password",
                // },
            ]
        },
        {
            title: "Detailed Info",
            bottomText: "Next: You can review your inputs before submission.",
            inputFields: [
                {
                    key: "age",
                    label: "Age",
                    type: "text",
                    fullWidth: false,
                },
                {
                    key: "gender",
                    label: "Gender",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "Male",
                        "Female",
                        "Other",
                        "Prefer Not To Say",
                    ]
                },
                {
                    key: "eligableToWork",
                    label: "Eligable To Work In This Region?",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "Yes",
                        "No"
                    ]
                },
                {
                    key: "referred",
                    label: "Referred By Existing Employee?",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "No",
                        "Yes",
                    ]
                },
                {
                    key: "mailingAddress",
                    label: "Mailing Address",
                    type: "text",
                    fullWidth: true,
                },
                {
                    key: "resumeFile",
                    label: "Upload Resume",
                    type: "file",
                    fullWidth: true,
                },
            ]
        },
        {
            title: "Address",
            bottomText: "Next: We are going to gather date of birth and other details.",
            inputFields: [
                {
                    key: "mailingAddress",
                    label: "Mailing Address",
                    type: "text",
                },
                {
                    key: "city",
                    label: "City",
                    type: "text",
                },
                {
                    key: "state",
                    label: "State / Provence",
                    type: "text",
                },
                {
                    key: "country",
                    label: "Country",
                    type: "text",
                },
            ]
        },
        {
            title: "Date Of BIrth & Other Information",
            bottomText: "Reminder: * denotes an optional field.",
            bottomText2: "Next: You will review the application and submit it.",
            inputFields: [
                {
                    key: "dob",
                    label: "Date Of Birth",
                    type: "date",
                },
                {
                    key: "sn",
                    label: "Social #",
                    type: "text",
                },
                {
                    key: "parentsAttended",
                    label: "Did either of your parents attend a college or university? *",
                    type: "text",
                },
                {
                    key: "maritalStatus",
                    label: "Marital Status *",
                    type: "select",
                    options: [
                        "",
                        "Single",
                        "Married",
                        "Seperated",
                        "Divorced",
                    ]
                },
                
                {
                    key: "employmentStatus",
                    label: "What is your current employment status?",
                    type: "select",
                    options: [
                        "",
                        "Un-Employed",
                        "Self-Employed",
                        "Full-Time",
                        "Part-Time",
                    ]
                },
                {
                    key: "militaryStatus",
                    label: "What is your military status?",
                    type: "select",
                    options: [
                        "",
                        "Active / National Guard / Reserve",
                        "Veteran",
                        "Military Spouse or Dependent",
                        "Not Applicable",
                    ]
                },
                {
                    key: "annualIncome",
                    label: "Please estimate your annual household income",
                    type: "text",
                },
                {
                    key: "paymentPlan",
                    label: "How do you plan to pay for your education?",
                    type: "select",
                    options: [
                        "",
                        "Self Pay",
                        "Tuition Reimbursement or Third Party",
                        "Federal Financial Aid (Loans/Grants)",
                        "State Grant",
                        "Scolarships",
                        "Military",
                        "Private Loan",
                        "Not Sure",
                    ]
                },
              
            ]
            
        },
    ]

    const applicationLocalName = useState(applicationNameParam || ("Integrity Application "+applicationData?.company+" "+applicationData?.title))

    const [stepsData, setStepsData] = useState([])
    useEffect(()=>{
        if(stepsDataParam)
            setStepsData([...stepsDataParam])
        else
            setStepsData([...stepsDataDefault])
    },[stepsDataParam])

    useEffect(()=>{
        applicationLocalName.current = applicationNameParam
    },[applicationNameParam])

    // The name of the application, used to save local data and applicationType attribute in the db
    // const applicationLocalName = "Integrity Staffing Application " + applicationData?.company + " " + applicationData?.title
    // If this is true the user will need to input a pin to resume, also the application data will only be saved in local data when next is pressed 
    // const requiresPin = requiresPinParam || false

    // #endregion Start

    // ================================================================================
    // #region Steps

    // Represents the application step index
    const [stepIndex, setStepIndex] = useState(0)

    function next(){
        // This should never happen, but will prevent an error if it does
        if(stepIndex > stepsData.length) return
        // Add the responses, if valid will return true, else false
        if(addTempResponses())
            // Move to the next step
            setStepIndex(stepIndex + 1)
    }
    function previous(){

        // Check for valid inputs and save if valid
        addTempResponses(true)

        // Move to the previous step
        if(stepIndex == 0) return
        setStepIndex(stepIndex - 1)
    
    }
    function submit(){
        // Set a submitted flag in state and localStorage to be loaded later
        setSubmittedApplication(true)
        window.localStorage.setItem(applicationLocalName+"submitted", true)

        // Update the db to show the application has been submitted 
        dbUpdate({submitDate: dateStringUTC()})

    }
    function unSubmit(){
        // Remove the submitted flag in state and localStorage
        setSubmittedApplication(false)
        window.localStorage.removeItem(applicationLocalName+"submitted")
    }

    // #endregion Steps

    // ================================================================================
    // #region Response Object & Save / Load

    // The object that contains all of the user responses
    const [responsesObject, setResponsesObject] = useState({})
    // When the input is changed response data is saved in a local temporary response object, when next is clicked it is checked and added if valid
    const [tempResponsObject, setTempResponsObject] = useState({})
    // Used to tell the user when they forgot an input
    const [message, setMessage] = useState()
    // Edit mode in review section
    const [reviewEdit, setReviewEdit] = useState()
    // When the user is returning a message will show to ask if they want to resume or restart
    const [showResumeQuestion, setShowResumeQuestion] = useState(false)
    const [submittedApplication, setSubmittedApplication] = useState()

    // When the input is changed response data is saved in a local temporary response object
    // If pin is required: when next is clicked it is checked and added if valid
    // If no pin is required: saves to the responsesObject and to the db on change
    function inputChanged(key, value){
        // console.log("input changed key: "+key+" value: "+value)
        // return
        // If the pin is required only save into local storage on next
        if(requiresPin){
            // Save the value into an object and/or local storage based on the key
            let tempTempResponsesObject = {...tempResponsObject}
            tempTempResponsesObject[key] = value
            
            setTempResponsObject(tempTempResponsesObject)

            // DB Update
            tempResponsesObjectRef.current = {...tempTempResponsesObject}
            clearTimeout(inputChangedTimer.current)
            inputChangedTimer.current = setTimeout(() => {
                // Maybe can just do this instead of using a ref
                dbUpdate({...tempTempResponsesObject})
            }, 500);

        }
        // If no pin is required save to local storage on every change
        else{
            // console.log("pin not required")

            updateResponseObject(key, value)
            // Add the new value to the responses object
            // let tempResponsesObject = {...responsesObject}
            // tempResponsesObject[key] = value

            // console.log("updated temp responses object: ")
            // console.log(tempResponsesObject)


            // // Save it, which will trigger useEffect and save the response data in local storage
            // setResponsesObject(tempResponsesObject)

            // Update the db with the new value
            // clearTimeout(inputChangedTimer.current)
            // inputChangedTimer.current = setTimeout(() => {
            //     // Maybe can just do this instead of using a ref
            //     dbUpdate({...tempResponsesObject})
            // }, 500);
            
        }
    
    }
    // Updates the response object in localStorage and the db
    function updateResponseObject(key, value){

        let tempResponsesObject = {...responsesObject}
        tempResponsesObject[key] = value

        // Save it, which will trigger useEffect and save the response data in local storage
        setResponsesObject(tempResponsesObject)

        clearTimeout(inputChangedTimer.current)
        inputChangedTimer.current = setTimeout(() => {
            // Maybe can just do this instead of using a ref
            dbUpdate({...tempResponsesObject})
        }, 500);
    }
    function fileUpload(key, file){
        if(!file) {
            // console.log("no file in file upload.")
            return
        }

        // Upload the file
        // Save the download url and file name in an object in the application data in the db
        // And maybe save the name in localStorage

        // If there is no application key create one and save it in local storage 

        // Get the applicaiton ID from local storage to be used in the storage path
        let applicationID = window.localStorage.getItem(applicationLocalName+"ID")
        // If it does not exist create it
        if(!applicationID)
            // Is this async?
            applicationID = dbUpdate({})

        let uploadTask = uploadBytesResumable(storageRef(storage, "applicationFiles2/"+applicationID+"/"+key), file)
        uploadTask.on("state_changed", 
            uploadSnap => {
                const progress = 100 * (uploadSnap.bytesTransferred / uploadSnap.totalBytes)
            },
            error => {
                let filePathObject = {
                    name: "Error Uploading "+file?.name+": "+error?.message,
                    uploadDate: dateStringUTC()
                }

                updateResponseObject(key ,filePathObject)
            }, 
            // upload complete
            ()=>{
                getDownloadURL(uploadTask.snapshot.ref).then(url => {
        
                    // Get the data for the file upload and put it in the object to be saved in the db
                    let filePathObject = {
                        downloadURL: url, 
                        storageURL:  "applicationFiles/"+applicationID+"/"+key,
                        name: file?.name,
                        uploadDate: dateStringUTC()
                    }

                    updateResponseObject(key, filePathObject)

                    // inputData.attachedFile = newImagePathObject

                    // Set the flags so the user can see the application was submitted
                    // setSubmitted(true)
                    // setMessage("Application Submitted (and file was uploaded)")
            
                    // Put the data in the db
                    // set(newApplicationRef, inputData) 

                })
            }
        )
    }
    let inputChangedTimer = useRef()
    let tempResponsesObjectRef = useRef()
    /*

        after a timeout set put the tempReponseObject into the db
        may need to use a tempResponseObjectRef

        will need to create an application key and add it to local storage
        and load it before saving, if there is none

        to get all would need responsesObject too

    */

    async function getApplicationID(){
     // Look for the saved application ID
     let applicationID = window.localStorage.getItem(applicationLocalName+"ID")
     // If there is one update that application
     if(applicationID){
         return applicationID
     }
     // If not create a new application ID in the database
     else{
         let newApplicationRef = push(ref(db, "applications/"))
         applicationID = newApplicationRef.key
         // Save it in local storage
         window.localStorage.setItem(applicationLocalName+"ID", newApplicationRef.key)

         return applicationID

     }
    }


    // Puts application data into the database
    function dbUpdate(objectToSave){


        // Look for the saved application ID
        let applicationID = window.localStorage.getItem(applicationLocalName+"ID")
        // If there is one update that application
        if(applicationID){
            update(ref(db, "applications/"+applicationID), objectToSave)
        }
        // If not create a new application ID in the database
        else{
            let newApplicationRef = push(ref(db, "applications/"))
            applicationID = newApplicationRef.key
            window.localStorage.setItem(applicationLocalName+"ID", newApplicationRef.key)

            let date = new Date()

            update(newApplicationRef, {applicationType: applicationLocalName+" "+applicationData?.title,...objectToSave, startDate: dateStringUTC()})
        }

        return applicationID

    }

    function dbUpdate2(objectToSave){
        let applicationID = getApplicationID() + ""
        update(ref(db, "applications/"+applicationID), objectToSave)
    }

    function deleteAllApplications(){
        set(ref(db, "applications/"), null)
    }
    // Put the temp responses into local storage
    function addTempResponses(previous){

        // Don't change the input data in the review page
        if(stepIndex == stepsData.length) return

        let tempTempResponsesObject = {...responsesObject}

        Object.entries(tempResponsObject).forEach(([key, value]) => {
            tempTempResponsesObject[key] = value
        })

        // Inputs don't need to be compolete if going to previous so if its not previous check
        let invalidInput = false
        if(!previous){
            let setpsInSection = stepsData[stepIndex]?.inputFields
            if(!setpsInSection) return
            setpsInSection.forEach(stepData => {
                if(stepData.optional) return
                if(!tempTempResponsesObject[stepData.key]){
                    // console.log("Please check "+stepData.label+" field.")
                    setMessage("Please check "+stepData.label+" field.")
                    invalidInput = true
                }
            })

        }
        
        // Save the responses, calls saveResponseObject from a useEffect
        setResponsesObject(tempTempResponsesObject)
        
        // Denotes that not all inputs are valid
        if(invalidInput){
            return false
        } 
        else{
            // Reset the message
            setMessage()
            
            // Denotes that all inputs are valid
            return true
        }
            
    }

    // When the response object changes save it in local storage
    useEffect(()=>{
        // if(Object.entries(responsesObject).length > 0)
            saveResponseObject()
    },[responsesObject])
    function saveResponseObject(){
        if(responsesObject && typeof responsesObject === "object" && Object.entries(responsesObject).length > 0){
            window.localStorage.setItem(applicationLocalName+"applyResponseObject", JSON.stringify(responsesObject))
        }
    }

    // Save the step index when it loads
    useEffect(()=>{
        if(stepIndex == 0) return
        window.localStorage.setItem(applicationLocalName+"stepIndex", stepIndex.toString())

    },[stepIndex])

    // On start load the response object
    useEffect(()=>{
        // window.localStorage.removeItem("applyResponseObject")
        loadResponseObject()
        // Load this to see if the user has submitted the application
        loadSubmitted()
    },[])
    function loadResponseObject(){
        let loadedResponseObject = window.localStorage.getItem(applicationLocalName+"applyResponseObject")
        if(loadedResponseObject && loadedResponseObject !== "null"){
            loadedResponseObject = JSON.parse(loadedResponseObject)
            setResponsesObject(loadedResponseObject)

            // If there is a pin show the resume box
            if(requiresPin && loadedResponseObject.pin){

                setShowResumeQuestion(true)

                // If there is a pin load the steop index
                loadStep()
            }
        }
    }
    // Load the step index on resume
    function loadStep(){
        let loadedStepIndex = window.localStorage.getItem(applicationLocalName+"stepIndex")
        if(!loadedStepIndex) return
        loadedStepIndex = Number.parseInt(loadedStepIndex)
        if(!Number.isNaN(loadedStepIndex)){
            setStepIndex(loadedStepIndex)
        }
    }
    function loadSubmitted(){
        let submitted = window.localStorage.getItem(applicationLocalName+"submitted")
        if(submitted && submitted !== "null")
            setSubmittedApplication(true)
    }

    
    // #endregion Response Object

    // ================================================================================
    // #region Resume

    function checkPinAndResume(){

        let pinInput = document.getElementById("resumePinInput")?.value
        if(pinInput && pinInput === responsesObject.pin){
            // Reset the message
            setMessage()

            // Hide the resume window
            setShowResumeQuestion(false)

        }else{
            setMessage("Pin did not match, please try again or start over.")

        }

    }
    function startOver(){
        // Set response object
        window.localStorage.removeItem(applicationLocalName+"applyResponseObject")
        setTempResponsObject({})
        setResponsesObject({})
        // Reset stap index
        window.localStorage.removeItem(applicationLocalName+"stepIndex")
        setStepIndex(0)
        // Reset submitted status
        setSubmittedApplication(false)
        window.localStorage.removeItem(applicationLocalName+"submitted")

        // Hide the resume winodw
        setShowResumeQuestion(false)

        // Remove this so data saves to a new application
        window.localStorage.removeItem(applicationLocalName+"ID")

        // Reset the message
        setMessage()
    }

    // #endregion Resume

    // ================================================================================
    // #region Display

    // Display buttons conditionally based on the step index
    function buttons(){
        if(stepIndex == 0)
        // if(stepd[stepIndex] === "basic")
            return (
                <div className='applyButton'>
                    <button onClick={next}>Save & Continue</button>
                </div>
            )
        else if (stepIndex == stepsData.length)
            return (
                <div className='applyButtons'>
                    <div className='applyButton applyButtonLast'>
                        <button onClick={previous}>Previous</button>
                    </div>
                    <div className='applyButton'>
                        <button onClick={submit}>Submit</button>
                    </div>
                </div>
            )
        else
            return (
                <div className='applyButtons'>
                    <div className='applyButton applyButtonLast'>
                        <button onClick={previous}>Previous</button>
                    </div>
                    <div className='applyButton'>
                        <button onClick={next}>Save & Continue</button>
                    </div>
                </div>
            )
    }
    // Converts the array with all of the input objects into jsx elements
    function inputFields(){
        let inputs = []

        // For the review section
        if(stepIndex == stepsData.length){
        // For the review step show all inputs with their values
        // if(steps[stepIndex] === "review"){
            Object.entries(stepsData).forEach(([stepKey, stepData]) => {
                let inputFields = stepData.inputFields
                if(!inputFields || !Array.isArray(inputFields)) return
                inputFields.forEach(inputData => {
                    if(!inputData.key?.includes("pin"))
                        inputs.push(
                            <div className={'inputLine '+(inputData.fullWidth ? "inputLineWhole":"")}  key={stepKey+""+inputData.key+"review"}>
                                <div className='inputLabel'>{inputData?.label}</div>
                                {reviewEdit?
                                    <input defaultValue={responsesObject?.[inputData?.key]}></input>
                                    :
                                    <div className='reviewDiv'>
                                        {typeof responsesObject?.[inputData?.key] === "object"?
                                        <pre>
                                            {responsesObject?.[inputData?.key]?.name}
                                        </pre>
                                        :
                                        responsesObject?.[inputData?.key]
                                    }
                                    </div>
                                }
                            </div>
                            
                        )
                })
            })
            inputs.push(
                <div className='applicationDisclaimer'>
                    {"Please review your inputs before submitting. To edit inpus press the previous button."}
                </div>
            )

            return inputs
        }

        // If not in the review step get the inputs based on the step index, steps, and stepData

        // Get the step key from the steps and stepIndex
        // let stepKey = steps?.[stepIndex]
        // if(!stepKey) return

        // Get the step date with the step key
        let inputFields = stepsData[stepIndex]?.inputFields
        // console.log("inputFields")
        // console.log(inputFields)
        if(!inputFields || !Array.isArray(inputFields)) return
        // console.log("inputFields2")
        // console.log(inputFields)
        
        // Create JSX for the inputs to be displayed
        inputFields.forEach(inputData => {
            // console.log("inputData")
            // console.log(inputData)
            inputs.push(inputDataToJSX(inputData, inputData.key))
        })

        // console.log("inputs")
        // console.log(inputs)

        // Return the JSX so it is displayed
        return inputs

    }
    // Converts an input object to an input field that can be displayed
    function inputDataToJSX(inputData, key){
        // The default text input
        if(inputData.type === "text"){
            return(
                <div className={'inputLine '+(inputData.fullWidth ? "inputLineWhole":"")} key={key} onChange={(e)=>inputChanged(inputData.key, e.target.value)} >
                    {/* {key} */}
                    <div className='inputLabel'>{inputData?.label}</div>
                    <input defaultValue={responsesObject?.[inputData?.key] || inputData.defaultValue}></input>
                </div>
            )
        }
        else if(inputData.type === "textarea"){
            return(
                <div className={'inputLine '+(inputData.fullWidth ? "inputLineWhole":"")} key={key} onChange={(e)=>inputChanged(inputData.key, e.target.value)} >
                    {/* {key} */}
                    <div className='inputLabel'>{inputData?.label}</div>
                    <textarea defaultValue={responsesObject?.[inputData?.key] || inputData.defaultValue}></textarea>
                </div>
            )
        }
        else if(inputData.type === "file"){
            return(
                <>
                    <div className='fileUploadContainer'>
                        <FileUploader
                            label={"Upload a File"}
                            // The storage path for the file
                            imagePath={"applicationFiles2/"+getApplicationID()+"/"+inputData.key}
                            // The path where the link to the file will be saved
                            dataPath={("applications/"+getApplicationID())}
                        ></FileUploader>
                    </div>
                    <div className={'inputLine fileInputLine '+(inputData.fullWidth ? "inputLineWhole":"")} key={key} onChange={(e)=>fileUpload(inputData.key, e.target.files[0])} >
                        {/* {key} */}
                        <div className='inputLabel'>{inputData?.label}</div>
                        <input type={inputData.type}></input>
                        <div className='inputLabel'>{"Saved File: "+responsesObject?.[inputData?.key]?.name}</div>
                    </div>
                </>
            )
        }
        // Selection inputs with options
        else if(inputData.type === "select"){
            return(
                <div className={'inputLine '+(inputData.fullWidth ? "inputLineWhole":"")} key={key}>
                    {/* {key} */}
                    <div className='inputLabel'>{inputData?.label}</div>
                    {/* <div>{responsesObject?.[inputData?.key]}</div> */}
                    <select defaultValue={responsesObject?.[inputData?.key] || inputData.defaultValue} onChange={(e)=>inputChanged(inputData.key, e.target.value)}>
                    {/* <select defaultValue={"Information Technology"} onChange={(e)=>inputChanged(inputData.key, e.target.value)}> */}
                        {inputData?.options.map((option, index) => (
                            <option key={option+""+index}>{option}</option>
                        ))}
                    </select>
                </div>
            )
        }
        // If there are date or password input types
        else{
            return(
                <div className={'inputLine '+(inputData.fullWidth ? "inputLineWhole":"")} key={key}>
                    {/* {key} */}
                    <div className='inputLabel'>{inputData?.label}</div>
                    <input type={inputData.type} defaultValue={responsesObject?.[inputData?.key] || inputData.defaultValue} onChange={(e)=>inputChanged(inputData.key, e.target.value)}></input>
                </div>
            )
        }
    }

    // #endregion Display

    return (
        <div className='page applicationPageBackground'>
            <div className='applyTopBar'>{""+applicationData?.title+" Application"}</div>
            {(showResumeQuestion || submittedApplication) ?
            
                // Resume application PIN check

                <div className='flexCenter resumeApplicationContainer'>
                    <div className='box resumeApplicationBox'>
                        {submittedApplication?
                            <>
                               <div className='applicationSubmittedMessage'>Application Submitted!</div>    
                               <div className='applicationButtons'>
                                   <div className='applyButton applyButtonLast'>
                                       <button onClick={unSubmit}>Review</button>
                                   </div>
                                   <div className='applyButton applyButtonLast'>
                                       <button onClick={startOver}>Start New</button>
                                   </div>
                               </div>
                           </>
                           :
                            <>
                                <div className='requmeApplicationTitle'>Application Progress Detected</div>
                                <div className='requmeApplicationDescription'>{message || "Please enter your pin to resume."}</div>
                                <div className='inputLine inputLineWhole'>
                                    <input placeholder='Application PIN' id='resumePinInput' autocomplete={"off"}></input>
                                </div>
                                <div className='applicationButtons'>
                                    <div className='applyButton applyButtonLast'>
                                        <button onClick={startOver}>Start Over</button>
                                    </div>
                                    <div className='applyButton' onClick={checkPinAndResume}>
                                        <button >Continue</button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div> 
                :   
                // Start or continue application
                <div className='applicationArea'>
                    {stepsData.length > 1 &&
                        <div className='applicatoinTitle'>
                            {stepsData[stepIndex]?.title ?
                                stepsData[stepIndex]?.title + " ("+ (stepIndex + 1)+" of "+Object.entries(stepsData).length+")"
                                :
                                "Review"
                            }
                        </div>
                    }
                    <div>
                        {inputFields()}
                    </div>
                    <div className='applicationMessages'>
                        <div className='errorMessage'>{message}</div>
                        <div className='applicationDisclaimer'>
                            {stepsData[stepIndex]?.bottomText}
                        </div>
                        <div className='applicationDisclaimer'>
                            {stepsData[stepIndex]?.bottomText2}
                        </div>
                    </div>
                    {buttons()}
                </div>
            }
        </div>
    )
}

export default ApplyPage2