import React, { useEffect, useState } from 'react'
import PositionDisplay from './PositionDisplay'
import { companyData } from '../../Data/Data'
import { useParams } from 'react-router-dom'
import { getPositionData } from '../../Global/Functions'

function PositionDisplayPage() {
    const {companyID, positionID, tabName} = useParams()
    const [positionData, setPositionData] = useState()

    useEffect(()=>{
        let tempPositionData = getPositionData(companyData, companyID, positionID)

        setPositionData(tempPositionData)
    
    }, [companyID, positionID])

    return (
    <div>
        <PositionDisplay positionDataParam={positionData} startingTab={tabName}></PositionDisplay>
    </div>
  )
}

export default PositionDisplayPage