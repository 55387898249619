import React from 'react'
import ApplyPage2 from './ApplyPage2'
import { useParams } from 'react-router-dom'
import ApplyBox2 from './ApplyBox2'
import { backgroundCheckInputSteps } from '../../Data/Data'

/*

    What information is needed here?
    SN, taxID, ID img
    need to app to see

*/
function BackgroundCheckApply() {

    const { applicantName } = useParams()
    let applicantFirstName
    let applicantLastName
    if(applicantName){
        let nameParts = applicantName.split("-")
        if(Array.isArray(nameParts) & nameParts.length > 0)
            applicantFirstName = nameParts[0]
        if(Array.isArray(nameParts) & nameParts.length > 1)
            applicantLastName = nameParts[1]
    }

    const applicationData = {
        title: "Background Check",

    }


  return (
    <div className='pageHeight flexCenter'>
        <div className='width80'>
            <ApplyBox2
                applicationName={"FreeFer Background Check "+applicationData.title}
                title={"Background Check"}
                stepsDataParam={backgroundCheckInputSteps}
            ></ApplyBox2>
        </div>
    </div>
  )
}

export default BackgroundCheckApply