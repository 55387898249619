import React, { useEffect, useRef, useState } from 'react'
import "./ApplyPage.css"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from '@firebase/storage'
import { db, storage } from '../../GlobalState/AppSlice'
import {  onValue, push, ref, set, update } from '@firebase/database'
import { dateStringUTC } from '../../Global/Functions'
import FileUploader from '../FileUploader/FileUploader'
import DBInput from '../DBInteraction/DBInput'
import "./ApplyBox2.css"
import { defaultStepsData } from '../../Data/Data'

/*
    ================================================================================
    Page Flow
    ================================================================================

    ________________________________________
    0) Steps Data

    Steps data is put in state

        If there is a steps data array provided as a parameter use that
        If not use the default steps data

    ________________________________________
    1) Load Progress:

    Load the application ID:
    
        The ID of the application is used as a key to save and retrieve data

        retrieve it based on the application name and save in local state
        or generate it and save it in localStorage.applicationName+ID and local state

    When the application ID has been loaded, load progress:

        progressData:   The input values and progress already entered by the user
        stepIndex:      Used to resume user step progress, loaded from localStorage.<applicationID>StepIndex
                        Saved in the db in the progressData object
        submitted:      If the application has been submitted, loaded from localStorage.<applicationID>Submitted
                        Saved in the db in the progressData object

        application inputValues are saved at applications/applicationID like so:
        applications/applicationID: {
            firstName: "applicant first name"
            lastName: "applicant last name",
            stepIndex: 2,
            submitted: true,
            resumeFile: {
                downloadURL: <The download url for the file, used to display the file>
                storageURL:  <The url of the storage location, used to delete the file>
                name: <the name of the file>,
                uploadDate: dateStringUTC() <The date that the file was uploaded>
            }
        }

    ________________________________________
    2)

    The stepData corresponding to the stepIndex is displayed
    
    ________________________________________
    3) User Input

    Input Field Change:
    User inputs into these fields 
        The data is saved in the database at applications/applicationID

    Next Step:
    When the user clicks next 
        The next index of input data is displayed
        The index is saved in applicationName+Step so the user can resume progress
        
    Review Step:
    When the final index is reached a review page is displayed
    
    Submit Application:
    When the user clicks submit applicationName+Submitted is set to true

    4)
    User may click review application or start over
        Review option will set submitted to false so the review page shows
        Starting over will remove the localStorage data, reset state, and start an new applicatoin


*/
function ApplyBox2({stepsDataParam, applicationName, title}) {

    const [stepsData, setStepsData] = useState(stepsDataParam || defaultStepsData)
    const [applicationID, setApplicationID] = useState()
    const [progressData, setProgressData] = useState({stepIndex: 0})
    const [message, setMessage] = useState()

    // Just making sure this updates in state
    useEffect(()=>{
        setStepsData(stepsDataParam || defaultStepsData)
    },[stepsDataParam])


    // ================================================================================
    // #region 1) Load Progress. Loads applicationID, stepIndex, and submitted status for users returning to a started application.

    // Load or create the application ID on start
    useEffect(()=>{

        // Load or create the application ID used to store or access application input data  
        loadApplicationID()

        // This refreshes the UI due to a lingering data bug
        refreshUI()

    },[applicationName])

    // The application ID is loaded or created. Will be used later to save or retrieve data
    function loadApplicationID(){

        // Progress data is refreshed in case the user clicks another position with another name
        setProgressData({stepIndex: 0})

        // Look in local storage for an application ID associated with this application name
        let tempApplicationID = window.localStorage.getItem(applicationName+"ID")


        // If there is one put it in local state
        if(tempApplicationID && tempApplicationID !== "null"){
            setApplicationID(tempApplicationID)
        }
        // If there is not an application ID create one
        else{

            // Generate an application ID by pushing to the application database
            let newApplicationRef = push(ref(db, "applications/"))
            let newApplicationID = newApplicationRef.key

            // Save it in local storage and local state
            window.localStorage.setItem(applicationName+"ID", newApplicationID)
            setApplicationID(newApplicationID)

            // Set the initial step index
            update(ref(db, "applications/"+newApplicationID), {stepIndex: 0, applicationName: applicationName, startDate: dateStringUTC()})
        }

    }

    // Load the application progress after the application ID is loaded or generated
    useEffect(()=>{
        // Wait until an application ID has been loaded or created to load the progress
        if(!applicationID) return

        loadProgress()

    },[applicationID])

    // Load the values already input by the user (if any)
    function loadProgress(){
        onValue(ref(db, "applications/"+applicationID), snap => {
            // If there are saved input values put them in state to display as defaultValues in the input lines
            
            // If there is no saved progress return
            if(!snap.val()) return
        
            // Ensuring there is a stepIndex
            let loadedProgressData = {...snap.val()}
            if(!loadedProgressData.stepIndex)
            loadedProgressData.stepIndex = 0

            // Save the loaded progress in state. Includes inputs, stepIndex, and submitted status  
            setProgressData(loadedProgressData)
            
        })
    }

    // This refreshes the UI due to a lingering data bug
    const [displayUI, setDisplayUI] = useState(true)
    function refreshUI(){
        setMessage()
        setDisplayUI(false)
        setTimeout(() => {
            setDisplayUI(true)
        }, 50);
    }
    
    // #endregion Load Progress

    // ================================================================================
    // #region 3) User Input


    // The next step is displayed
    function next(){

        // Validate the inputs
        if(!inputsAreValid()) return

        // Go to the next step
        let newStepIndex = (progressData.stepIndex || 0) + 1
        set(ref(db, "applications/"+applicationID+"/stepIndex"), newStepIndex)

    }
    function inputsAreValid(){

        // The name of the invalid input (never set if they are all valid)
        let invalidInput

        // Loot at the input fields on current step
        let stepData = stepsData[progressData.stepIndex || 0]
        stepData?.inputFields.forEach(inputObject => {

            // If it is optional ignore it
            if(inputObject.optional) return            

            // If the Check to see if the input key is present
            if(!progressData[inputObject?.key])
                invalidInput = inputObject?.label

        })

        // If there is an invalid input set the message so the user knows what to look at and return false to show that the inputs are not valid
        if(invalidInput){
            setMessage("Please check the "+invalidInput+" input")
            return false
        }

        // If the inputs are valid reset the message and return true
        setMessage()
        return true
    }
    function previous(){
        let newStepIndex = progressData.stepIndex - 1
        if(newStepIndex >= 0)
            set(ref(db, "applications/"+applicationID+"/stepIndex"), newStepIndex)
        else
            set(ref(db, "applications/"+applicationID+"/stepIndex"), 0)


    }

    // Set submitted to true in db progressData so the submitted screen shows
    function submitApplicaiton(){
        update(ref(db, "applications/"+applicationID), {submitted: true, submitDate: dateStringUTC()})

    }
    function unSubmitApplicaiton(){
        set(ref(db, "applications/"+applicationID+"/submitted"), false)

    }

    // Resets everyting and starts a new application
    function startOver(){
        // Remove the application ID from local storage
        window.localStorage.removeItem(applicationName+"ID")

        // In case there is a message
        setMessage()

        // Generate a new application ID
        loadApplicationID()

    }

    // ================================================================================
    // #endregion User Input

    // ================================================================================
    // #region Display Functions
    

    // The step converted into JSX (input step, review step, submitted step)
    function stepDisplay(){
        let stepIndex = progressData.stepIndex || 0

        // Make sure there is an applicatoin ID to save into
        if(!applicationID){
            return(
                <div>Loading...</div>
            )
        }

        // Submitted Screen
        if(progressData.submitted){
            return (
                <>
                    <div className='applicationSubmittedMessage'>
                        Application Submitted
                    </div>
                    <button onClick={unSubmitApplicaiton} className='applyBox2Button'>Review</button>
                    <button onClick={startOver} className='applyBox2Button'>Start Over</button>
                </>
            )
        }

        // Display inputs
        if(stepIndex < stepsData?.length || !progressData?.stepIndex){
            let stepData = stepsData[stepIndex || 0 ] 
            return (
                <>

                    {/* The Title */}
                    <div className='applicationBox2TopMessage'>{stepData.title + " ("+((progressData?.stepIndex + 1) || 1)+" of "+stepsData.length+")"}</div>
                    
                    {/* The Input Fields */}
                    {stepData?.inputFields?.map(inputObject => (
                        <DBInput
                            key={applicationName+inputObject.key}
                            // label={inputObject.label+(inputObject.optional ? " (optional)":"")}
                            label={inputObject.label}
                            savePath={"applications/"+applicationID+"/"+inputObject.key}
                            fileStoragePath={"applicationFiles3/"+applicationID}
                            defaultValue={progressData[inputObject.key]}
                            notActive={!applicationID || !inputObject.key}
                            type={inputObject.type}
                            fullWidth={inputObject.fullWidth}
                            options={inputObject.options}
                        ></DBInput>
                    ))}

                    {/* The bottom messages */}
                    <div className='applyBox2BottomMessage applyBox2ErrorMessage'>
                        {message}
                    </div>
                    <div className='applyBox2BottomMessage'>
                        {stepData.bottomText}
                    </div>

                    {/* The Buttons */}
                    <div>
                        {progressData.stepIndex !== 0 && <button onClick={previous} className='applyBox2Button'>Previous</button>}
                        <button onClick={next} className='applyBox2Button'>Next</button>
                    </div>
                </>
            )
        }

        // Review Page
        else{

            // Generate all of the inputs
            let reviewInputs = []
            stepsData.forEach(step => {
                reviewInputs = [...reviewInputs, ...step?.inputFields]
            })

            return(
                <div>
                    <div className='applicationBox2TopMessage'>Review Page</div>
                    {reviewInputs.map(inputObject=>(
                        <DBInput
                            key={applicationName+inputObject.key}
                            // label={inputObject.label+(inputObject.optional ? " (optional)":"")}
                            label={inputObject.label}
                            savePath={"applications/"+applicationID+"/"+inputObject.key}
                            fileStoragePath={"applicationFiles3/"+applicationID}
                            defaultValue={progressData[inputObject.key]}
                            notActive={!applicationID || !inputObject.key}
                            type={inputObject.type}
                            fullWidth={inputObject.fullWidth}
                            options={inputObject.options}
                            viewMode
                        ></DBInput>
                        // <div className='flexRow'>
                        //     <div>{inputObject.label}</div>
                        //     <div>{progressData[inputObject.key]}</div>
                        // </div>
                    ))}
                    <button className='applyBox2Button' onClick={previous}>Previous</button>
                    <button className='applyBox2Button' onClick={submitApplicaiton}>Submit</button>

                </div>
            )
        }
    }


    // #endregion Display Functions

    return (
        <div className='applyBox2' key={applicationName}>
            <div className='applyBox2Title'>{title}</div>
            <div className='applyBox2Inner'>
                {displayUI && stepDisplay()}
            </div>
        </div>
    )
}

export default ApplyBox2