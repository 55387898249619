import React, { useEffect, useState } from 'react'
import { companyData } from '../../Data/Data'
import PositionMapper from './PositionMapper'
import "./Positions.css"
import { useParams } from 'react-router-dom'
import PositionDisplay from './PositionDisplay'
import { companyDataToPositionsArray, objectToArray, scrollTop } from '../../Global/Functions'
import { isMobile } from 'react-device-detect'
import PositionDisplayWindow from './PositionDisplayWindow'

function CompanyPositions() {
  // Get the positoins for this company from the data
  // Map them
  // When one is clicked adjust the url to go to a page that displays the data for that position and the apply page
  
    // Create (of find) a page components or css class that makes cure the page height is set to a minimum height

    // Get the company name from the url
    const {companyID} = useParams() 

    // The positions that will be displayed
    const [positions, setPositions] = useState([])

    useEffect(()=>{

        // If there is no company name specified or if the all flag is given display all of the positions from all of the companieis
        if(!companyID)
            loadAllPositions()
        else
            loadCompanyPositions(companyID)

        scrollTop();

    },[companyID])

    function loadAllPositions(){
        let tempAllPositions = []
        Object.entries(companyData).forEach(([companyID, thisCompanyData]) => {
            tempAllPositions.push(...companyDataToPositionsArray(companyData, companyID))
        })
        setPositions(tempAllPositions)
    }
    function loadCompanyPositions(companyID){
        setPositions([...companyDataToPositionsArray(companyData, companyID)])
    }

    const [selectedPositionData, setSelectedPositionData] = useState()
    function positionClickFunction(clickedPositionData){
        setSelectedPositionData(clickedPositionData)
    }

    return (
        <>
            {isMobile?
                <>
                    {selectedPositionData ? 
                        <PositionDisplayWindow
                            positionDataParam={selectedPositionData}
                            setSelectedPositionData={setSelectedPositionData}
                        ></PositionDisplayWindow>
                        :
                        <div className='column500'>
                            <div className='title positionColumTitle'>Open Positions</div>
                            <div className='positoinsColumn'>
                                <PositionMapper positions={positions} positionClickFunction={positionClickFunction}></PositionMapper>
                            </div>
                        </div>
                    }
                </>
                :
                <div className='width100 alignLeft pageHeight'>
                    <div className='column500 mL20'>
                        <div className='title positionColumTitle'>Open Positions</div>
                        <div className='positoinsColumn'>
                            <PositionMapper positions={positions} positionClickFunction={positionClickFunction}></PositionMapper>
                        </div>
                    </div>
                    {/* {"companyName: "+companyName} */}
                    <PositionDisplay positionDataParam={selectedPositionData}></PositionDisplay>
                </div>
            }
        </>
  )
}

export default CompanyPositions