import { deleteObject, getDownloadURL, ref as storageRef, uploadBytesResumable } from '@firebase/storage'
import React, { useRef, useState } from 'react'
import { db, storage } from '../../GlobalState/AppSlice'
import { ref, set } from '@firebase/database'
import "./FileUploader.css"
import { dateString } from '../../Global/Functions'

/*
    Parameters:
    fileStoragePath: Is the storage path where the file will be uploaded
    databasePath:    Where the object used to represent the file and access it is stored     
    fileDataObject: data about the currently saved image (if any, used to display or delete)
    example:
    let newFileDataObject = {
        downloadURL: url, 
        fileStoragePath: fileStoragePath +""+ file?.name,
        databasePath: databasePath, 
        name: file?.name,
        uploadDate: dateString()
    }

    When a file is put into the input (checked in onChange)
    it is uplaoded based on provided   
    it saves the download url in the given inpagePath?

*/

function FileUploader({fileStoragePath, databasePath, fileDataObject, fileIdentifier, label}) {  
    
    // The current progress of the upload
    const [progress, setProgress] = useState(0)
    // The preview url that displays the image while it is uploading
    const [previewUrl, setPreviewUrl] = useState()
    // Determines if the progress bar shows
    const [showProgressBar, setShowProgressBar] = useState()
    // Used to identify the hidden input field so the label can interact with it
    const fileInputRef = useRef()

    // ============================================================
    // #region DB Interaction: uploadFile, deleteImageFunction

    function uploadFile(file){

        // Make sure a valid file and paths are available 
        if(!fileStoragePath || !databasePath || !file?.name) return        

        // Upload the image to imagePath
        const uploadTask = uploadBytesResumable(storageRef(storage, fileStoragePath +""+ file?.name), file)
        uploadTask.on("state_changed", snapshot => {
            const progress = 100 * (snapshot.bytesTransferred / snapshot.totalBytes)
            setProgress(progress)
            
            if(progress < 100)
                setShowProgressBar(true)
            else    
                setTimeout(() => {
                    setShowProgressBar(false)
                }, 2000)
        }, 
        e => {
            console.log("file upload error:")
            console.log(e)
            console.log(e?.message)
        },
        ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then(url => {
                // If there is already an image there delete it
                if(fileDataObject?.fileStoragePath)                    
                    deleteFile()

                // This object will be saved in the database to represent and access the file in storage
                let newFileDataObject = {
                    downloadURL: url, 
                    fileStoragePath: fileStoragePath +""+ file?.name,
                    databasePath: databasePath, 
                    name: file?.name,
                    uploadDate: dateString()
                }

                // Put the new file data object in the database at the specified path
                let newRef = ref(db, databasePath)
                set(newRef, newFileDataObject)

            })
        })
    }

    // Deletes the file associated with the provided paths
    function deleteFile(){

        // Remove the preview url
        setPreviewUrl()

        // Deletes the file object from the database
        set(ref(db, databasePath), null)

        // If there is no storage url there no file to delete
        if(!fileDataObject?.fileStoragePath) return

        // Deletes the file from storage
        deleteObject(storageRef(storage, fileDataObject?.fileStoragePath))

    }
 
    // #endregion DB Interaction

    // ============================================================
    // #region Image Uploader Interactions: fileDragOver, fileSelection, fileDrop, clickFunction, selectNewFile

    function fileDragOver(e){
        e.preventDefault()
    }
    function fileSelection(e){

        // Get the file from the event
        let file = e.target.files[0]  

        // Create a preview url and display it
        setPreviewUrl(URL.createObjectURL(file))

        // Create a preview url and display it
        uploadFile(file)
    }
    function fileDrop(e){
        e.preventDefault()
        
        // Get the file from the event
        let file = e.dataTransfer.files[0]
        
        // Create a preview url and display it
        setPreviewUrl(URL.createObjectURL(file))
        
        // Upload the file (and show progress bar)
        uploadFile(file)

    }

    // When the component is clicked this will prevent a new file being uploaded if there is already a file
    function clickFunction(e){
        if(previewUrl || fileDataObject?.downloadURL){
            e.preventDefault()
            // setShowImageWindow(true)
        }
        // Else it will open the selector because that is the default behavior

    }

    // Simulates a click on the hidden input so a new file can be selected
    function selectNewFile(e){
        e.stopPropagation()
        e.preventDefault()

        fileInputRef.current?.click()
    }

    // #endregion

    return (
        <div className='fileUploader'>

            {/* The input field that allows file upload (hidden and activated with a label or on button click) */}
            <input type='file' ref={fileInputRef} className='hidden' id={'fileInput'+fileIdentifier} onChange={fileSelection}></input>

            {/* Check to see if there is an existing file to display */}
            {(fileDataObject?.downloadURL || previewUrl)?
                <>
                    {/* If there is display it */}
                    <iframe src={fileDataObject?.downloadURL || previewUrl} className='fileUploadPreview'></iframe>    
                    <div className='flexRow fileUploaderButtons'>
                        <button onClick={deleteFile} className='fileUploaderOptionButton'>Delete</button>    
                        <button onClick={selectNewFile} className='fileUploaderOptionButton'>Select New File</button>    
                    </div>                
                </>
                :
                <>
                    {/* A message telling the user what to upload */}
                    <div>{label}</div>

                    {/* The Label that the user interacts with to drop or select a file, shows if there is no file to display */}
                    <label for={'fileInput'+fileIdentifier} onDragOver={fileDragOver} onDrop={fileDrop} className='imageSelectorLabel' onClick={clickFunction}>
                        <div className='fileUploadDropZone'>
                            
                            {/* User Message */}
                            <div> Drop File Or Click To Select</div>                
                            
                        </div>
                    </label>
                </>
            }
            
            {/* Progress Bar */}
            {showProgressBar && 
                <div className='progressBar'>
                    <div className='progressBarInner' style={{width: progress+"%"}}></div>                    
                    <div className='progressBarText'>
                        { Math.round(progress)+"%"}
                    </div>
                </div>
            }

        </div>
    )
}

export default FileUploader