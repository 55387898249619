import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import TopNav from './Components/Nav/TopNav';
import BottomNav from './Components/Nav/BottomNav';
import CompanyPositions from './Components/Positions/CompanyPositions';
import PositionDisplayPage from './Components/Positions/PositionDisplayPage';
import CompanyOverview from './Components/CompanyOverview/CompanyOverview';
import AuthPage from './Components/Auth/AuthPage';
import BackgroundChecksLanding from './Components/Home/BackgroundChecksLanding';
import BackgroundCheckApply from './Components/Careers/BackgroundCheckApply';
import { isMobile } from 'react-device-detect';
import ApplyBox2 from './Components/Careers/ApplyBox2';

function App() {

  /*
    ========================================
    Live Site: https://integrity-staffing.co/
    Live Dev Site: https://integrity-staffing.onrender.com/
    Email: hr@integrity-staffing.co
    https://www.linkedin.com/hiring/jobs/3784059082/detail/?trk=talent_jobPost_optimize
    Our mission is securing talent for businesses and fostering the growth of careers by connecting and empowering.
    ========================================
    To add positions:
      Go to the Data.js file
    
    To update the questions in the application: 
      Data.js: defaultStepsData (used in ApplyBox2.js)

    To update the questions in the background check:
      Data.js: backgroundCheckInputSteps (used in BackgroundCheckApply.js)
      
    ========================================
    To add

    control whats on the site from the admin dash:
      the postings (including descriptoin, company, etc)
      the questions that are asked in the application (custom for the posting or default)
      the questions that are asked in the bg check page
    
    in admin dash
      sort by most recent
      new applications show in the main page stats box
      archive with ability to view archived
      put into folders with ability to view the folders

    ========================================
    6 months later, still have not made use of all of this work I put into this. 
    Need to do something with it now. 
    
    I'm literally sleeping in the back of a rental car without proper phone service
    I spend my life helping others and I am without basic necessities becaues I am blocked every time I try to do anything
    also robbed in Dublin, by Rave, Natalie, the car rental company from the thing in Cork, the house rental situation 
    having to work in coffee shops where I'm not wanted, getting kicked out of places, being homeless
    I have money and can't get a car, or a place to live, or an acocunt, I'm losing my income,
    the hotel in town was swarming with people who are not from there and the owner was extremely rude and mean and kicked out recipients
    a few bouncers have been the same way
    this entire situation is rediculous and obviously serves to suppress and then radicalize me,  
    would like to be able to just live normally, choosing where I live, who I live with, the car I drive, have normal relationships, etc

    test the job app
      make sure it works from link and data goes to admin dash (done)
      add postings, driver, others (done)

    test the bg check form
      make sure it works from link and data goes to admin dash (done)
      modify the inputs, hopefully it is easy to modify (done)
    
    post jobs
      either data from the site is ok, or link to a post 
      find posting sites
      make posting with the link

    Example link:
    https://integrity-staffing.co/#/Freedom-Fertility/Database-Design

  */
     
  /*

    ========================================
    TODO Soon
    Test application process in private tab
    check the admin site
    post links

    TODO Future:
    find which info is needed (by applying yourself)
    update apply areas

    Whalley TODO:
    hash router
    test apply area
    login area works and goes to dashbgoard with downloadale cert
    mobile view

    ========================================
    For components project (not really what this is anymore)

    startup CSS

    banner
    tile (multiple types)
    topnav (with mobile version that can go on top or bottom based on parameter)
    bottom nav
    collapasable box (like year in whalley program detail)
    collapasable section  (like course descriptoin in whalley program detail)
    manu window
    auth window
    image homepage section
    text homepage section
    inline topnav from ocala auto
    services page
    reviews

    search bar that filters objects
    filters bar
    chat 
      chats window
      chat box
      multi chat box
    image uploader
    application form
    positions page connected to application form
    faw section
    yourube box
    info box like in extended info on freefer profile
    image viewer window
    images box
    back to top bar


    pages
    events page
    positions
    apply
    contact
    research / articles

    firebase setup
    images


    easy-apply

    homepages:
    will talk about what it is
    
    /companyName 
    will have say layout as the homepage but with button to view open positions, or show them there

    /companyName/positionName
    displays the application for that position

    companyName/backgroundCheck/name
    shows an apply area with special inputs that the user will input for completing a bg check 


    examples:

    homepage:
    https://fadv.com/solutions/employment-background-checks/

    bg check page
    https://www.backgroundchecks.com/
    https://checkr.com/signup

    job postings
    https://www.indeed.com/?from=gnav-homepage
    https://www.glassdoor.com/Job/sales-representative-jobs-SRCH_KO0,20.htm

    from homepage
    banner 
    image text lr alternating
    orange box 
    puple banner
    circle images with links

    https://www.backgroundchecks.com/
    pricing squares
    top section squares

    whalley
    topnav
    bottom nav
    tile
    apply 

    positions styling from other job sites 

    in mobile vew can have all of them in a column covering the full width
    when user clicks one it displays the information and apply box

    in desktop the column is on the left and the position data is on the right with the apply box
    if the screen gets small enough it displays like in mobile

    good example:
    https://www.google.com/search?q=jobs&sca_esv=587889185&sxsrf=AM9HkKnUzhtRTk3TgNt9v1eFs-D6cN0I2Q:1701743203067&source=hp&ei=Y4puZc43jb7Q8Q-65LPICA&iflsig=AO6bgOgAAAAAZW6Yc5DTGoWX873s-I5iKXQi844hGgWf&uact=5&oq=jobs&gs_lp=Egdnd3Mtd2l6IgRqb2JzMgsQABiABBixAxiDATIIEAAYgAQYkgMyCxAAGIAEGIoFGJIDMg4QABiABBixAxiDARjJAzIIEAAYgAQYsQMyBRAAGIAEMgsQABiABBixAxiDATIFEAAYgAQyBRAAGIAEMgUQABiABEjzGFAAWLwXcAZ4AJABAJgBxAGgAcgHqgEDOC4yuAEDyAEA-AEBqAIKwgIFEC4YgATCAg0QABiABBixAxiDARgKwgIHECMY6gIYJ8ICChAjGIAEGIoFGCfCAgQQIxgnwgILEAAYgAQYigUYkQLCAg4QLhiABBiKBRixAxiDAcICERAuGIAEGLEDGIMBGMcBGNEDwgILEC4YgAQYsQMYgwHCAgsQLhiABBjHARivAcICBxAAGIAEGAo&sclient=gws-wiz&ibp=htl;jobs&sa=X&ved=2ahUKEwj_yvu6n_eCAxXXODQIHb0TCmoQutcGKAF6BAgUEAY#htivrt=jobs&htidocid=K4VCT6doGfe9kBF8AAAAAA%3D%3D&fpstate=tldetail

    example dru screen
    https://veriscreen.net/solutions/drug-screening/

  */

  return (
    <div className={"App" + (isMobile ? " AppMobile":"")}>
        <TopNav></TopNav>
        <div className='topnavOffset'>
          <Routes>
            
            {/* Page Links */}
            <Route path="/" element={<Home></Home>}/>
            <Route path="/test" element={<ApplyBox2 title="Test Application" applicationName="Test Application"></ApplyBox2>}/>
            <Route path="/all-positions" element={<CompanyPositions></CompanyPositions>}/>
            <Route path="/:companyID" element={<CompanyOverview></CompanyOverview>}/>
            <Route path="/background-checks" element={<BackgroundChecksLanding></BackgroundChecksLanding>}/>
            <Route path="/account" element={<AuthPage></AuthPage>}/>
            
            {/* Background Check Links */}
            <Route path="/background-check" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            <Route path="/background-check/:applicantName" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            <Route path="/:companyID/background-check" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            <Route path="/:companyID/background-check/:applicantName" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            
            {/* Position Links */}
            <Route path="/:companyID/:positionID" element={<PositionDisplayPage></PositionDisplayPage>}/>
            <Route path="/:companyID/:positionID/:tabName" element={<PositionDisplayPage></PositionDisplayPage>}/>
          
          </Routes>
          <BottomNav></BottomNav>
        </div>
    </div>
  );
}

export default App;
